import React from "react"
import Layout from "../shared/layout/layout"
import Seo from "../shared/seo/seo"
import "../styles/company.scss"
import { useStaticQuery, graphql } from "gatsby"
import ContatcImg from "../components/contact/contact-img"

const Contact = () => {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          contacts {
            paragraphs {
              address
              info
              mail
              tel
              open
              link_address
              rag
              pec
            }
            title
            img_description
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <Seo title="Contact" />
      <div className="no-gutters row">
        <div className=" col-xl-6 col-lg-6 col-md-12">
          <div className="row no-gutters">
            <div className="col-xl-11 col-lg-11 col-md-11 col-sm-11 col-xs-12">
              <div className="main-img">
                <ContatcImg></ContatcImg>
              </div>
            </div>
            <div className="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-xs-12">
              <div className="title-img">
                {data.site.siteMetadata.contacts.img_description}
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-6 col-lg-6 col-md-12">
          <div className="paragraphs">
            <p className="paragraph-item pb-0">
              <span>{data.site.siteMetadata.contacts.paragraphs.info}</span>
            </p>
            <span className="mt-4"></span>
            <p className="paragraph-item pb-0">
              {data.site.siteMetadata.contacts.paragraphs.open}
            </p>
          </div>
          <div className="paragraphs margin-info-contact">
            <div className="container p-0 ">
              <div className="row paragraph-item pb-0">
                <div className="col-5">Telefono </div>
                <div className="col-12 mt-3">
                  {data.site.siteMetadata.contacts.paragraphs.tel}
                </div>
              </div>
              <hr></hr>

              <div className="row paragraph-item pb-0">
                <div className="col-5">Email </div>
                <div className="col-12 mt-3">
                  <a
                    href="mailto:falegnamerialepri@gmail.com"
                    rel="noreferrer"
                    className="link_lepri mail"
                  >
                    {data.site.siteMetadata.contacts.paragraphs.mail}
                  </a>
                </div>
              </div>
              <hr></hr>
              <div className="row paragraph-item pb-0">
                <div className="col-5">Pec </div>
                <div className="col-12 mt-3">
                  <a
                    href="mailto:falegnamerialepri@gmail.com"
                    rel="noreferrer"
                    className="link_lepri mail"
                  >
                    {data.site.siteMetadata.contacts.paragraphs.pec}
                  </a>
                </div>
              </div>
              <hr></hr>

              <div className="row paragraph-item pb-0">
                <div className="col-5">Indirizzo </div>
                <div className="col-12 mt-3">
                  <a
                    className="link_lepri"
                    rel="noreferrer"
                    href={
                      data.site.siteMetadata.contacts.paragraphs.link_address
                    }
                    target="_blank"
                  >
                    {data.site.siteMetadata.contacts.paragraphs.address}
                  </a>
                </div>
              </div>
              <hr></hr>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Contact
